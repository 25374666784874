var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden left-side lg:tw-flex",attrs:{"sm":"12","lg":"4"}},[_c('OnboardingLeft')],1),_c('v-col',{staticClass:"right-side scroll",staticStyle:{"position":"relative"},attrs:{"sm":"12","lg":"8"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 img-logo",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/logos/blue.png"),"alt":"logo"}}),_c('p',{staticClass:"welcome tw-mt-0"},[_vm._v("Verification")]),_c('p',{staticClass:"admin tw-mt-5"},[_vm._v(" Let’s get your unique identifier ")]),_c('p',{staticClass:"admin tw-mt-2"},[_vm._v(" details and you'd be set to go. ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveData)}}},[_c('div',{staticClass:"shift-button-down"},[_c('v-row',{staticClass:"upload-div"},[_c('v-col',{attrs:{"lg":"6","sm":"6"}},[_c('p',{staticClass:"upload-div-text "},[_vm._v("Utility Bill")]),_c('button',{staticClass:"button-logo-input",class:_vm.utilityImage ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onPickUtilityFile()}}},[(_vm.utilityImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.color,"indeterminate":""}}):_vm._e(),(_vm.utilityImg)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.utilityImg,"alt":""}}):(_vm.utilityImageLink)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.utilityImage,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("@/assets/camera.png"),"alt":""}})],1),_c('input',{ref:"UTILITY",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onUtilityImagePicked}})])],1),_c('v-row',{staticClass:"form"},[_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"NIN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("NIN")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{staticClass:"tw-mt-2",attrs:{"placeholder":"546 6666 5432","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.nationalIdentityNumber),callback:function ($$v) {_vm.nationalIdentityNumber=$$v},expression:"nationalIdentityNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"BVN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("BVN")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"221 6666 5432","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.bvn),callback:function ($$v) {_vm.bvn=$$v},expression:"bvn"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"btn-div tw-flex lg:tw-justify-end tw-justify-center"},[_c('router-link',{staticClass:"btn-div-text",attrs:{"to":{ name: 'IndividualAgentAddress' }}},[_c('img',{staticClass:"btn-div-img",attrs:{"src":require("../../assets/arrow-back.svg"),"alt":""}}),_c('span',[_vm._v(" Previous ")])]),_c('button',{staticClass:"btn-div-text",attrs:{"disabled":!_vm.formIsValid},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_c('span',[_vm._v(" Account Details ")]),_c('img',{staticClass:"btn-div-img",attrs:{"src":require("../../assets/arrow_forward.svg"),"alt":""}})])],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }