<template>
  <div class="tw-flex tw-w-full tw-h-auto" style="background: white">
    <v-col sm="12" lg="4" class="tw-hidden left-side lg:tw-flex">
      <OnboardingLeft />
    </v-col>
    <v-col sm="12" lg="8" class="right-side scroll" style="position: relative">
      
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/logos/blue.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6 img-logo">

        <p class="welcome tw-mt-0">Verification</p>
        <p class="admin tw-mt-5">
          Let’s get your unique identifier
        </p>
        <p class="admin tw-mt-2">
          details and you'd be set to go.
        </p>
        
        
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveData)">
            <div class="shift-button-down">
              <v-row class="upload-div">
                <v-col lg="6" sm="6">
                  <p class="upload-div-text ">Utility Bill</p>
                  <button class="button-logo-input" type="button" @click="onPickUtilityFile()" :class="utilityImage ? 'border-0' : ''">
                    <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;"
                                         v-if="utilityImageLoading"></v-progress-circular>
                    <img :src="utilityImg" alt="" class="button-logo-img" v-if="utilityImg">
                    <img :src="utilityImage" alt="" class="button-logo-img" v-else-if="utilityImageLink">
                    <img src="@/assets/camera.png" alt="" class="button-logo-input-img" v-else>
                  </button>
                  <input type="file" ref="UTILITY" accept="image/*" style="display: none" @change="onUtilityImagePicked">

                </v-col>

              </v-row>
            <v-row class="form">
              <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                <ValidationProvider name="NIN" rules="required" v-slot="{ classes, errors }">
                  <label  class="label">NIN</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field placeholder="546 6666 5432" solo hide-details type="text" v-model="nationalIdentityNumber" required class="tw-mt-2"></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-4">
                <ValidationProvider name="BVN" rules="required" v-slot="{ classes, errors }">
                  <label  class="label">BVN</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field placeholder="221 6666 5432" solo hide-details type="text" v-model="bvn" required></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

              </v-col>
            </v-row>
            </div>
        <div class="btn-div tw-flex lg:tw-justify-end tw-justify-center">
          <router-link :to="{ name: 'IndividualAgentAddress' }" class="btn-div-text">
            <img src="../../assets/arrow-back.svg" alt="" class="btn-div-img"> 
            <span> 
              Previous  
            </span>
          </router-link>

          <button class="btn-div-text" :disabled="!formIsValid" @click.prevent="saveData">
             <span>
              Account Details
            </span>
            <img src="../../assets/arrow_forward.svg" alt="" class="btn-div-img">
          </button>
        </div>
          </form>
        </validation-observer>
      </v-col>

    </v-col>
  </div>
</template>

<script>
  import OnboardingLeft from './OnboardingLeft'
  import check from '@/assets/thumbs.svg'
  import { uploadToCloudinary} from '@/services/api/APIService'

  export default {
    name: "CorporateAgentOnboardingVerification",
    components: {
      OnboardingLeft

    },
    data() {
      return {
        dialog: false,
        loading: false,
        check,
        value : false, 
        confirmValue : false,
        nationalIdentityNumber: '',
        bvn : '',
        color: "#004AAD",
        utilityImg: '',
        utilityImage: '',
        utilityImageLoading: false,
        utilityImageLink: '',
      }
    },
    computed: {
      storedData() {
        return JSON.parse(sessionStorage.getItem('mobileAgentData'))
      },
      formIsValid() {
        return this.nationalIdentityNumber
            && this.bvn
            && this.utilityImageLink}
    },
    methods:{
      validNin(nin){
        if(nin.length === 11) {
          return true;
        }
        else {
          this.$displaySnackbar({
            message: "Not  a valid  NIN e.g 88012123454",
            success: false,
          });
          return false;
        }
      },
    
      validBvn(bvn){
        if (bvn.length === 11){
          return true
        }
        else {
          this.$displaySnackbar({message: 'Not a valid BVN e.g 22109878762'})
        }
      },
      clearSession() {
        sessionStorage.removeItem('data')
      },
      toggleModal(){
        this.dialog = !this.dialog
      },

      onPickUtilityFile() {
        this.$refs.UTILITY.click()
      },
      onPickCacFile() {
        this.$refs.CAC.click()
      },
      onUtilityImagePicked(event) {
        const files = event.target.files[0]
        try {
          console.log(files)
          this.utilityImageLoading = true

          const fileReader =  new FileReader()
          fileReader.addEventListener('load', async () => {
            this.utilityImg = fileReader.result
            this.utilityImage = files.name

            let newData = {
              file: this.utilityImg,
              upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
            }
            const response = await uploadToCloudinary(newData)
            this.utilityImageLink = response.data.url
            this.utilityImageLoading = false

          })
          fileReader.readAsDataURL(files)

        } catch (error) {
          console.log(error.response);
        }

      },
      async saveData() {
        if (!this.validNin(this.nationalIdentityNumber)){
          return this.$displaySnackbar({ message: "Not a valid National Identification Number", success: false })
        }
        if ( this.validBvn(this.bvn)){
          let data = {
            ...this.storedData,
            utilityBill : this.utilityImageLink,
            nationalIdentityNumber: this.nationalIdentityNumber,
            bvn: this.bvn,
          }
          sessionStorage.setItem('mobileAgentData', JSON.stringify(data))
          await this.$router.replace({name: 'IndividualAgentAccountDetails'})
        }
      },
      fillFields(){
        let data = JSON.parse(sessionStorage.getItem('mobileAgentData'))
        if (data){
          this.nationalIdentityNumber = data.nationalIdentityNumber
          this.bvn = data.bvn
          this.utilityImageLink = data.utilityBill
          this.utilityImg = data.utilityBill
        }
      },
    },
    mounted() {
      this.fillFields()
    }
  }
</script>

<style lang="scss" scoped>
button:disabled,
button[disabled]{
  opacity: 0.7;
}

.left-side{
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/onboarding-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0!important;
  color: #ffffff;

  @media screen and (max-width: 1024px){
    display: none;
  }
}

.upload-div {
  margin-top: 37px;
  margin-left: 10px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  @media (max-width:1264px) {
    margin-left: 0;
  }
}

.upload-div .upload-div-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #4F4F4F;
  margin-bottom: 10px !important;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}

.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.right-side-body{
  width: 100%;
  padding:7rem;
  @media screen and (min-width: 1440px) {
    padding-top: 4.25rem;
  }
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body > .img-logo{
  @media screen and (min-width: 1024px){
    display: none;
  }
}
  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 99vh;
    height: 98vh;
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

.right-side-body .welcome {
  font-family: "Inter", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #004AAD;
  @media screen and (max-width: 1024px){
    margin-top: 50px;
  }
}

.right-side-body .admin {
  width: 355px;
  font-family: "Inter", serif!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #828282;
}


.label {
  font-family: "Inter",serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.btn-div{
  margin-top: 52px;
  @media screen and (max-width: 1023px){
    margin-top: 25px;
  }
}

.btn-div .btn-div-text:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  width: 161px;
  border-radius: 10px;
  background: white;
  color: #004AAD;
  border: 1px solid #004AAD;
  .btn-div-img {
    margin-right: 5px;
  }
}

.btn-div .btn-div-text:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35px;
  padding: 10px 30px;
  width: 200px;
  border-radius: 10px;
  background: #004AAD;
  color: white;
  .btn-div-img {
    margin-left: 5px;
  }
}

.btn-div .btn-div-btn {
  margin-left: 25px;
  // padding: 10px 30px;
  width: 161px;
  
  border-radius: 10px;
  background: #004AAD;
  color: white;
  span {
    display: flex; 
    align-items: center; 
    justify-content: center;

  }
  .btn-div-img {
    margin-left: 5px;
  }
}

.form {
  margin-top: 46px;
}

.input-upload-box{
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  .img {
    margin-right: 10px;
    width: 72px;
    height: 72px;
    border: 1px solid #BDBDBD;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .imgg {
      width: auto;
      height: 100%;
      border-radius: 10px;
    }
  }
  .input-upload-box-btn {
    color: #004AAD;
  }
}

.form {
  margin-top: 37px;
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
}
::v-deep .v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 0 !important;
}
.shift-button-down{
  min-height: 55vh;
}
</style>